var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "baidu-map",
        {
          staticClass: "map-page",
          attrs: {
            mapClick: false,
            dragging: true,
            center: _vm.center,
            zoom: _vm.zoom,
            "continuous-zoom": true,
            "scroll-wheel-zoom": true,
            doubleClickZoom: false,
          },
          on: { ready: _vm.initMap },
        },
        [_c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }