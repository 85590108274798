<template>
  <div class="container">
    <baidu-map
      class="map-page"
      :mapClick="false"
      :dragging="true"
      :center="center"
      :zoom="zoom"
      @ready="initMap"
      :continuous-zoom="true"
      :scroll-wheel-zoom="true"
      :doubleClickZoom="false"
    >
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    </baidu-map>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    resize: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      center: {
        lng: 104.114129,
        lat: 37.550339
      },
      zoom: 5,
      labelOverlayMap: new Map(),
      mapStyleJson: [
        {
          featureType: 'country',
          elementType: 'labels',
          stylers: {
            visibility: 'off'
          }
        }
      ],
      zIndex: 0
    }
  },
  watch: {
    params(newVal, oldVal) {
      const isEmpty = !newVal || newVal.length === 0
      if (isEmpty) {
        this.clearMap()
        return
      }
      this.setCityData(newVal)
    },
    resize(newVal, oldVal) {}
  },
  methods: {
    initMap(MapObj) {
      this.BMap = MapObj.BMap
      this.map = MapObj.map
      this.myGeo = new this.BMap.Geocoder()
      this.map.addEventListener('click', (e) => {})
      this.map.setMapStyleV2({
        styleJson: this.mapStyleJson
      })
    },
    setCityData(data) {
      const myData = data ? data.filter((p) => p.cityName) : []
      myData.forEach((p) => {
        const cityName = p.cityName
        let flag = cityName.endsWith('市')
        if (flag) {
          p.cityName = cityName.substr(0, cityName.length - 1)
        }
        flag = cityName.endsWith('地区')
        if (flag) {
          p.cityName = cityName.substr(0, cityName.length - 2)
        }
      })
      const keyList = []
      myData.forEach((p) => {
        keyList.push(p.cityName)
        if (!this.labelOverlayMap.has(p.cityName)) {
          const options = {
            position: new this.BMap.Point(p.lng, p.lat),
            offset: new this.BMap.Size(-42, -42)
          }
          const content =
            "<div class='city-info bgcolor'>\n" + p.cityName + '\n' + '<span>' + p.count + '</span> </div>'
          const label = new this.BMap.Label(content, options)
          label.setStyle({
            border: 'none',
            backgroundColor: 'transparent'
          })
          label.setTitle(p.cityName + ' ' + p.count)
          // label.addEventListener('click', this.handleLabelClick.bind(this, p.cityName))
          label.addEventListener('mouseover', this.handleLabelClick.bind(this, p.cityName))
          this.map.addOverlay(label)
          this.labelOverlayMap.set(p.cityName, label)
        }
      })

      // 移除不存在的覆盖物
      if (this.labelOverlayMap.size > 0) {
        for (const [key, value] of this.labelOverlayMap) {
          if (keyList.indexOf(key) < 0) {
            this.map.removeOverlay(value)
            this.labelOverlayMap.delete(key)
          }
        }
      }
    },
    handleLabelClick(key, e) {
      const el = e.target.ca
      if (el) {
        el.style.zIndex = ++this.zIndex
      }
    },
    clearMap() {
      if (this.labelOverlayMap.size > 0) {
        for (const [key, value] of this.labelOverlayMap) {
          if (value) {
            // 清除覆盖物
            this.map && this.map.removeOverlay(value)
            this.labelOverlayMap.delete(key)
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.clearMap()
  }
}
</script>
<style lang="less">
.map-page {
  .BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
  }
  .bgcolor {
    background: #05ac9c !important;
  }
  .bgcolor:hover {
    background: #088ee3 !important;
  }
}
.city-info {
  width: 60px;
  height: 60px;
  line-height: 1;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: #fff;
  span {
    margin-top: 4px;
  }
}
</style>
<style lang="less" scoped>
.container {
  height: 100%;
  width: 100%;
  .map-page {
    height: 100%;
  }
}
</style>
